<template>
  <div class="sales-addons-list fixed-draggable-dynamic-table-wrapper-height">
    <draggable-dynamic-table ref="saleClosedList"
                             :options="options"
                             :columns="columnsLabel"
                             :data="data"
                             :zero-top-radius="true"
                             @filter:remove="setFilter($event, true)"
                             @filter:set="setFilter($event)"
                             @sort:set="setSort($event)"
                             @row:clicked="handleRowClicked($event)"
                             @load:more="getClosedInvoices"/>
  </div>
</template>

<script>
  import axios from 'axios'
  import {checkUserPermissions} from "../../../../../assets/js/functions";
  import CustomIcon from "../../../../../components/customIcon/customIcon.vue";
  import {getClosedInvoices} from "../../../../../http/requests/club/closedInvoices";
  import historyStates from "@/mixins/historyStates";

  export default {
    name: "saleClosedList",
    components: {CustomIcon},
    metaInfo () {
      return {
        title: this.$t('closed.title')
      }
    },
    mixins: [historyStates],
    data() {
      return {
        filters: {},
        selectedFilters: [],
        sorts: ['order[0]=created_at,desc'],
        data: [],
        page: 1,
        total_count: null,
        loadingTimer: 0,
        options: {
          id: 'saleClosedList',
          rowKeyField: 'id'
        },
        columnsLabel: [
          {
            field: 'timesCount',
            i18n: 'closed.list.table.header.timesCount',
            width: 'calc(100% / 10)',
            minWidth: 150,
            sortable: true,
            filter: true,
            filterTypes: [
              {
                icon: 'equals',
                name: 'برابر باشد',
                i18n: 'draggableTable.filter.types.equals',
                id: 1
              },
              {
                icon: 'not-equal',
                name: 'برابر نباشد',
                i18n: 'draggableTable.filter.types.notEqual',
                id: 2
              },
              {
                icon: 'less-than-equal',
                name: 'کوچکتر مساوی',
                i18n: 'draggableTable.filter.types.lessThanEquals',
                id: 4
              },
              {
                icon: 'greater-than-equal',
                name: 'بزرگتر مساوی',
                i18n: 'draggableTable.filter.types.greaterThanEquals',
                id: 3
              }
            ],
          },
          {
            field: 'creator',
            i18n: 'closed.list.table.header.creator',
            align: 'center',
            width: 'calc((100% / 10) * 2)',
            minWidth: 200,
            sortable: true,
            filter: true,
            filterTypes: [
              {
                icon: 'search',
                name: 'شامل شود',
                i18n: 'draggableTable.filter.types.search',
                id: 1
              }
            ]
          },
          {
            field: 'createdAt',
            i18n: 'closed.list.table.header.createdAt',
            align: 'center',
            width: 'calc((100% / 10) * 2)',
            minWidth: 200,
            sortable: true,
            filter: true,
            filterType: 'date',
          },
          {
            field: 'description',
            i18n: 'closed.list.table.header.description',
            align: 'center',
            width: 'calc((100% / 10) * 2)',
            minWidth: 200,
            sortable: true,
            filter: true,
            filterTypes: [
              {
                icon: 'search',
                name: 'شامل شود',
                i18n: 'draggableTable.filter.types.search',
                id: 1
              }
            ]
          },
        ],
        actions: [
          {
            toolbar: [
              {
                id: {name: 'insertClosedInvoices'},
                type: 'link',
                icon: 'PLUS',
                iconPack: 'useral',
                color: 'success',
                permission: 'closed_invoice.create'
              }
            ],
            leftToolbar: [
              {
                id: 'printTable',
                icon: 'PRINT',
                iconPack: 'useral'
              },
              {
                id: 'downloadTable',
                icon: 'DOWNLOAD',
                iconPack: 'useral'
              },
              {
                id: {name: 'closedInvoicesTrash'},
                type: 'link',
                icon: 'TRASH',
                iconPack: 'useral',
                permission: 'closed_invoice.delete'
              },
              {
                id: 'settingTable',
                icon: 'icon-settings',
                iconPack: 'feather'
              }
            ]
          }
        ]
      }
    },
    created() {
      this.$nextTick(() => {
        this.$store.dispatch('auth/setAccessToken')
        this.$store.dispatch('updateNavbarActions', this.actions[0])
        this.$store.dispatch('updateContentNavbarStyle', 'sticky')
      })

      if (history.state.hasOwnProperty('data') && this.$store.state.helper.prevRoute.name !== null) {
        this.getTableInfoFromHistory()
      } else {
        this.getClosedInvoices()
      }
    },
    methods: {
      checkUserPermissions(permission) {
        return checkUserPermissions(permission)
      },
      getClosedInvoices() {
        clearTimeout(this.loadingTimer)
        this.loadingTimer = setTimeout(() => {
          if (this.data.length < this.total_count || !this.total_count) {
            if (this.$refs.saleClosedList && this.data.length === 0) this.$refs.saleClosedList.loadMoreStatus = 'FirstLoad'
            else if (this.$refs.saleClosedList && this.data.length > 0) this.$refs.saleClosedList.loadMoreStatus = 'Loading'

            getClosedInvoices(this.page, this.filters, this.sorts).then((response) => {
              const invoices = response.data

              if (invoices.data.length > 0) {
                invoices.data.forEach((invoice) => {

                  this.data.push({
                    route: {name: 'closedInvoiceProfile', params: {id: invoice.id}},
                    id: invoice.id,
                    description: invoice.note || '',
                    timesCount: invoice.lines_count || '',
                    creator: invoice.creator.role && invoice.creator.role.id === 1 ? this.$t('closed.labels.bySystem') : `${invoice.creator.name || ''} ${invoice.creator.family || ''}`,
                    createdAt: invoice.invoice_date.split(' ')[0]
                  })
                })
              }


              this.total_count = invoices.pagination.total
              this.page = invoices.pagination.current_page + 1

              // if (response.data.pagination.current_page === 1) {
              //   const row_index = this.columnsLabel.map((e) => {
              //     return e.field
              //   }).indexOf('rowNumber')
              //   this.columnsLabel[row_index].footer.value = response.data.pagination.total
              // }

              if (this.$refs.saleClosedList) this.$refs.saleClosedList.loadMoreStatus = ''
            }).catch((error) => {
              if (this.$refs.saleClosedList && !axios.isCancel(error)) this.$refs.saleClosedList.loadMoreStatus = 'Danger'
            })
          }
        }, 400)
      },
      setFilter(filters) {
        this.selectedFilters = filters
        let filters_list = {}
        Object.keys(filters).forEach((key) => {
          switch (key) {
            case 'description':
              if (filters[key].search !== '') filters_list.note = filters[key].search
              break

            case 'createdAt':
              if (filters[key].search !== '') filters_list.date = filters[key].search
              break

            case 'creator':
              let search = filters[key].search
              if (search === 'سیستم' || search === 'سیستمی') {
                search = 'system'
              }
              if (filters[key].search !== '') filters_list.creator = search
              break

            case 'timesCount':
              if (filters[key].search !== '') filters_list.linesCount = `${filters[key].search},${filters[key].type.id}`
              break
          }
        })

        this.data = []
        this.page = 1
        this.total_count = 0
        this.filters = filters_list
        this.$nextTick(() => {
          this.getClosedInvoices()
        })
      },
      setSort(sorts) {
        const sorts_list = []
        Object.keys(sorts).forEach((key) => {
          switch (key) {
            case 'description':
              sorts_list.push(`order[0]=note,${sorts[key] ? 'desc' : 'asc'}`)
              break

            case 'createdAt':
              sorts_list.push(`order[0]=invoice_date,${sorts[key] ? 'desc' : 'asc'}`)
              break

            case 'creator':
              sorts_list.push(`order[0]=creator,${sorts[key] ? 'desc' : 'asc'}`)
              break

            case 'timesCount':
              sorts_list.push(`order[0]=lines_count,${sorts[key] ? 'desc' : 'asc'}`)
              break
          }
        })

        if (sorts_list.length === 0) {
          this.sorts.push('order[0]=updated_at,desc')
        }

        this.data = []
        this.page = 1
        this.total_count = 0
        this.sorts = sorts_list
        this.$nextTick(() => {
          this.getClosedInvoices()
        })
      },
      handleRowClicked () {
        this.setTableInfoToHistory()
      },
      handleReloadList() {
        this.data = []
        this.page = 1
        this.total_count = 0
        this.getClosedInvoices()
      },
      handleClick(id) {
        document.getElementById(id).click()
      }
    }
  }
</script>

<style scoped>

</style>
